import type { MenuDataCommon } from "common-components-nuxt-types/menuData";

export async function useBasicMenuData() {
  const nuxtApp = useNuxtApp();

  const initialized = useState<boolean>(
    "useBasicMenuData_initialized",
    () => false,
  );
  const data = useState<MenuDataCommon | undefined>("useBasicMenuData_data");

  await callOnce(async () => {
    if (!initialized.value) {
      initialized.value = true;
      data.value = await nuxtApp.$api("/api/basicMenuData");
    }
  });

  return {
    data,
  };
}
