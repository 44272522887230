<template>
  <div
    id="layout"
    class="flex flex-col h-screen items-center bg-neutral-9 contrast:bg-c-secondary-0"
  >
    <HeaderBasicHeaderContent />
    <PrivacyPolicyCookie></PrivacyPolicyCookie>
    <div class="relative w-full grow flex flex-row overflow-hidden">
      <main
        class="px-1 sm:py-2 sm:px-2 flex flex-col overflow-hidden grow gap-2"
      >
        <div id="main_content" class="flex grow overflow-auto pb-4 grid-cols-1">
          <slot />
        </div>
        <DefaultFooter :menu-data="menuData"></DefaultFooter>
      </main>
    </div>
  </div>
</template>
<script setup lang="ts">
const config = useRuntimeConfig();
const { data: menuData } = await useBasicMenuData();

useHead({
  link: [
    {
      rel: "icon",
      type: "image/svg+xml",
      href: `${config.app.baseURL}/assecods_favicon.svg`,
    },
  ],
});
</script>
